import axios from "axios";

const API_URL = "https://jaris-api.vercel.app/api/home";

const getHomeData = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const homeDataService = {
  getHomeData,
};

export default homeDataService;
