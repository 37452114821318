import axios from "axios";

const API_URL = "https://jaris-api.vercel.app/api/service";

const getServices = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const servicesService = {
  getServices,
};

export default servicesService;
