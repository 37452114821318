import React from "react";
import { motion } from "framer-motion";
import { textVariant } from "../../utils/motion";
import { SectionWrapper } from "../../hoc";

const EventSection = () => {
  return (
    <div>
      <motion.div variants={textVariant()} className="service-timeline">
        <p className="service-timeline__title">
          Cryo-EM and Cryo-VR augmented workshop, 2024
        </p>
        <p className="service-timeline__sub-title">
          Take advantage of our awesome service
        </p>
      </motion.div>

      <div className="mt-10 flex flex-col">
        <div className="flex flex-col">
          <p className="service-timeline__sub-title mb-2">Featuring:</p>
          <ul className="flex flex-col gap-1 list-disc">
            <li>Principles and Applications of Cryo-EM</li>
            <li>Cryo-EM instrumentation and hands-on in VR</li>
            <li>Data collection and processing</li>
            <li>Map analysis and model predictions</li>
            <li>Structure-based virtual screening</li>
          </ul>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-medium text-brand-navyblue">
          Date: 11th and 12th January 2024 Registration link:{" "}
          <a
            href="https://forms.gle/nSHfT1YizW7QD7nY7"
            target="_blank"
            rel="noreferrer"
            className="text-brand-cyan"
          >
            https://forms.gle/nSHfT1YizW7QD7nY7
          </a>
        </p>

        <p>
          A Brief Summary about Cryo-EM vis-à-vis CryoVR Cryo-Electron
          Microscopy (Cryo-EM) is a state-of-the-art imaging technique in
          structural biology. It involves freezing biological samples in
          vitreous ice at extremely low temperatures to preserve their native
          structure. By directing a high-energy electron beam through the frozen
          sample, researchers can generate two-dimensional projection images.
          These images, collected from various angles, are then used to
          reconstruct a detailed three-dimensional model of the biological
          structure. Cryo-EM has revolutionized structural biology by enabling
          the study of complex targets, like membrane proteins, with
          unprecedented resolution. Its ability to capture dynamic molecular
          processes make it a crucial tool in various scientific disciplines,
          including biological sciences, biochemistry, pharmacology, and even
          materials science. As technology advances, cryo-EM continues to
          contribute significantly to understanding molecular structures and
          interactions, impacting fields such as drug discovery and
          therapeutics.
        </p>

        <div className="flex flex-col mt-3">
          <p className="service-timeline__sub-title mb-2">
            Meet some of our facilitators!
          </p>

          <div className="grid grid-cols-4 gap-5">
            <div className="flex flex-col">
              <p className="font-semibold">Prof Wen Jiang</p>
              <p className="text-sm text-brand-cyan">
                (Structural Biology, Computational Biology and Biophysics,
                Purdue University, USA)
              </p>
            </div>

            <div className="flex flex-col">
              <p className="font-semibold">Prof Daisuke Kihara </p>
              <p className="text-sm text-brand-cyan">
                (Structural Biology, Computational Biology and Biophysics,
                Purdue University, USA)
              </p>
            </div>

            <div className="flex flex-col">
              <p className="font-semibold">Prof Yingjie Chen</p>
              <p className="text-sm text-brand-cyan">
                (Computer graphic design, Purdue Polytechnique Institute, USA)
              </p>
            </div>

            <div className="flex flex-col">
              <p className="font-semibold">Prof Titilayo Johnson </p>
              <p className="text-sm text-brand-cyan">
                (Department of Biochemistry, University of Jos, Nigeria)
              </p>
            </div>

            <div className="flex flex-col">
              <p className="font-semibold">Dr Thomas Klose </p>
              <p className="text-sm text-brand-cyan">
                (Structural Biology, Purdue University, USA)
              </p>
            </div>

            <div className="flex flex-col">
              <p className="font-semibold">Mr Abayomi Adegboyega </p>
              <p className="text-sm text-brand-cyan">
                (Biological Sciences, USA)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionWrapper(EventSection);
