import React from "react";
import { MutableButton } from "../../components/Button";
import { RiArrowRightCircleFill } from "react-icons/ri";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// CUSTOM COMPONENTS
import { SectionWrapper } from "../../hoc";
import { StackedCard } from "../../components/Cards";
import { getServices } from "../../features/services/servicesSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";

const ServicesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { services, isLoading } = useSelector((state) => state.services);

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="services">
      <div className="services__insilico">
        <p>To run an insilico assay click the button below</p>
        <MutableButton
          text={"Run in-silico assay"}
          startIcon={<RiArrowRightCircleFill fontSize={20} />}
        />
      </div>

      <div className="services__card-layout">
        {services?.data?.map((item) => (
          <StackedCard
            key={item._id}
            name={item.name}
            amount={item.price}
            src={require(`./service/${item?.photo}`)}
            onClick={() => navigate(`/servicesdetail/${item?.service_id}`)}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionWrapper(ServicesPage);
