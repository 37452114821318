import React from "react";
import SectionWrapper from "../../hoc/SectionWrapper";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getBlogs } from "../../features/blog/blogSlice";
import Spinner from "../../components/Spinner";

const BlogDetail = () => {
  const dispatch = useDispatch();
  const { blogs, isLoading } = useSelector((state) => state.blogs);
  const { id } = useParams();

  const blogDetail = () => {
    if (blogs) {
      const post = blogs?.data?.find((post) => post?.post_id === id);
      return post;
    }
    return null;
  };

  const post = blogDetail();

  useEffect(() => {
    dispatch(getBlogs());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="blogdetail">
      <div className="blogdetail__image">
        <img src={require(`./blog/${post?.photo}`)} alt="BLOG IMG" />
      </div>

      <div className="blogdetail__description">
        <h2
          className={`font-bold text-3xl leading-10 tracking-wide text-brand-navyblue mb-1`}
        >
          {post?.title}
        </h2>

        <p className="mb-2">{post?.desc}</p>

        <p className="text-xs text-tertiary">by {post?.author}</p>
      </div>
    </div>
  );
};

export default SectionWrapper(BlogDetail);
