import React from "react";
import SectionWrapper from "../../hoc/SectionWrapper";
import MutableButton from "../../components/Button/MutableButton";
import { MdArrowCircleRight, MdAlternateEmail } from "react-icons/md";
import { GrMapLocation } from "react-icons/gr";
import { GiRotaryPhone, GiAlarmClock } from "react-icons/gi";
import { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        <div className="md:col-span-2">
          <p className="font-bold text-3xl leading-10 tracking-wide text-brand-navyblue mb-10 ">
            Send Us a Message
          </p>
          <form>
            <div className="w-full space-x-0 md:space-x-4 flex flex-col md:flex-row justify-between items-center mb-10">
              <input
                className="w-full rounded-xl border border-slate-400 p-1 px-3 mb-5 md:mb-0"
                type="text"
                placeholder="Enter your Name"
              />
              <input
                className="w-full rounded-xl border border-slate-400 p-1 px-3"
                type="email"
                placeholder="Enter your Email"
              />
            </div>

            <div className="mb-10">
              <input
                className="rounded-xl p-2 border border-slate-400 w-full h-32"
                placeholder="Enter your Message"
              />
            </div>

            <div className="flex justify-end items-center">
              <MutableButton
                startIcon={<MdArrowCircleRight fontSize={25} />}
                text={"Send Now"}
              />
            </div>
          </form>
        </div>

        <div className="">
          <p className="font-bold text-3xl leading-10 tracking-wide text-brand-navyblue mb-10">
            Our Contacts
          </p>
          <div className="flex flex-col">
            <div className="flex items-center mb-5">
              <GiRotaryPhone fontSize={40} />
              <div className="flex flex-col ml-3">
                <p className="font-semibold">Call</p>
                <p className="text-sm">+234 8165 075 168 / +234 8060 775 453</p>
              </div>
            </div>
            <div className="flex items-center mb-5">
              <GiAlarmClock fontSize={40} />
              <div className="flex flex-col ml-3">
                <p className="font-semibold">Work Time</p>
                <p className="text-sm">Mon - Fri 8 AM - 5 PM</p>
              </div>
            </div>
            <div className="flex items-center mb-5">
              <MdAlternateEmail fontSize={40} />
              <div className="flex flex-col ml-3">
                <p className="font-semibold">Email</p>
                <p className="text-sm">jarisresearch@gmail.com</p>
              </div>
            </div>
            <div className="flex items-center mb-5">
              <GrMapLocation fontSize={40} />
              <div className="flex flex-col ml-3">
                <p className="font-semibold">Location</p>
                <p className="text-sm">Jos, Plateau State, Nigeria</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionWrapper(Contact);
