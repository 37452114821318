import React from "react";
import { motion } from "framer-motion";
import { SectionWrapper } from "../../hoc";

// UTILS
import { fadeIn, textVariant } from "../../utils/motion";
import { InsulinCanvas } from "../Canvas";

const WelcomeSection = () => {
  return (
    <div className="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-3">
      <div>
        <motion.div className="jaris-welcome" variants={textVariant()}>
          <p className="jaris-welcome__title">Welcome to Jaris Computational</p>
          <p className="jaris-welcome__title underline text-brand-cyan">
            Biology Centre
          </p>
        </motion.div>
        <motion.div
          variants={fadeIn("", "", 0.1, 1)}
          className="jaris-welcome__para"
        >
          <p className="">
            The process of drug discovery has been challenging over the years
            due to the technicalities, time consumption and financial
            implications. With the development of computers and computer
            programs, and recent advances in the field of bioinformatics,
            biophysics, structural biology and computational biology, Jaris
            Computational Biology Centre employs new skills and techniques to
            facilitate the process of drug design and development vis-à-vis
            other biologically related problems.
          </p>
        </motion.div>
      </div>

      <div className="bg-[#D2DFBE] rounded-md">
        <InsulinCanvas />
      </div>
    </div>
  );
};

export default SectionWrapper(WelcomeSection);
