import React from "react";
import { motion } from "framer-motion";
import "react-vertical-timeline-component/style.min.css";

// CUSTOM HOOKS
import { textVariant } from "../../utils/motion";
import SectionWrapper from "../../hoc/SectionWrapper";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { icon3 } from "../../assets";

// CUSTOM COMPONENTS

const ServiceCard = ({ service, short }) => {
  return (
    <VerticalTimelineElement
      contentStyle={{
        background: "#1d1836",
        color: "#fff",
      }}
      contentArrowStyle={{ borderRight: "7px solid  #232631" }}
      date={"Jaris Service"}
      dateClassName="text-brand-navyblue"
      iconStyle={{ background: "#383E56" }}
      icon={
        <div className="flex justify-center items-center w-full h-full">
          <img
            src={icon3}
            alt={service.name}
            className="w-[60%] h-[60%] object-contain"
          />
        </div>
      }
    >
      <div>
        <p
          className="text-secondary text-[16px] font-semibold"
          style={{ margin: 0 }}
        >
          {service.name}
        </p>
      </div>

      <ul className="mt-5 list-disc ml-5 space-y-2">
        <li className="text-white-100 text-[14px] pl-1 tracking-wider">
          {short ? service.desc.slice(0, 150) + "..." : service.desc}
        </li>
      </ul>
    </VerticalTimelineElement>
  );
};

const ServiceTimeline = ({ services, short }) => {
  return (
    <div>
      <motion.div variants={textVariant()} className="service-timeline">
        <p className="service-timeline__title">Our Services</p>
        <p className="service-timeline__sub-title">
          Take advantage of our awesome service
        </p>
      </motion.div>

      <div className="mt-20 flex flex-col">
        <VerticalTimeline lineColor="#00A7E1">
          {services?.map((service, index) => (
            <ServiceCard
              key={`service-${index}`}
              service={service}
              short={short}
            />
          ))}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default SectionWrapper(ServiceTimeline);
