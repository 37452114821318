import React, { useEffect } from "react";
import SectionWrapper from "../../hoc/SectionWrapper";
import MutableButton from "../../components/Button/MutableButton";
import { useDispatch, useSelector } from "react-redux";
import { getPublications } from "../../features/publication/publicationSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";

const Publication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { publications, isLoading } = useSelector(
    (state) => state.publications
  );

  useEffect(() => {
    dispatch(getPublications());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
      {publications?.data?.map((pub) => (
        <div
          key={pub?.pub_id}
          className="border-b-8 rounded-lg border-brand-navyblue p-5 bg-brandWhite"
        >
          <MutableButton
            text={"Research Link"}
            onClick={() => window.open(pub?.url, "_blank")}
            className={"bg-transparent text-secondary"}
          />
          <p className="font-medium text-brand-navyblue text-lg leading-relaxed mb-5">
            {pub?.title}
          </p>

          <p className="text-sm">{pub?.abstract.slice(0, 150)}...</p>

          <MutableButton
            onClick={() => navigate(`/publicationdetail/${pub?.pub_id}`)}
            className={"bg-transparent text-secondary"}
            text={"Read More..."}
          />
        </div>
      ))}
    </div>
  );
};

export default SectionWrapper(Publication);
