import React from "react";

const NavLogo = () => {
  return (
    <div className="nav-bar__content__logo">
      <ul className="nav-bar__content__list">
        <li className="nav-bar__content__list-item">JARIS</li>
        <li className="nav-bar__content__list-item">RESEARCH</li>
        <li className="nav-bar__content__list-item">JARIS</li>
        <li className="nav-bar__content__list-item">RESEARCH</li>
      </ul>
    </div>
  );
};

export default NavLogo;
