import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineCloseCircle } from "react-icons/ai";

// CUSTOM COMPONENTS
import NavContact from "./NavContact";
import NavLogo from "./NavLogo";

const NavBar = () => {
  const [burger, setBurger] = useState(false);
  return (
    <>
      <NavContact />
      <div className="nav-bar">
        <div className="nav-bar__content-container">
          <div className="nav-bar__content">
            <NavLogo />

            {/* HAMBURGER */}
            <div className="md:hidden mr-5">
              <GiHamburgerMenu fontSize={30} onClick={() => setBurger(true)} />
            </div>

            <nav className={burger ? "nav-bar__nav2" : "nav-bar__nav"}>
              <div className="md:hidden mb-5">
                <AiOutlineCloseCircle
                  className="animation-spin"
                  fontSize={30}
                  onClick={() => setBurger(false)}
                />
              </div>
              <ul className="nav-bar__list">
                <li className="nav-bar__list-item">
                  <NavLink to={"/"}>Home</NavLink>
                </li>
                <li className="nav-bar__list-item">
                  <NavLink to={"about"}>About</NavLink>
                </li>
                <li className="nav-bar__list-item">
                  <NavLink to={"services"}>Services</NavLink>
                </li>
                <li className="nav-bar__list-item">
                  <NavLink to={"contact"}>Contact</NavLink>
                </li>
                <li className="nav-bar__list-item">
                  <NavLink to={"blogs"}>Blog</NavLink>
                </li>
                <li className="nav-bar__list-item">
                  <NavLink to={"publication"}>Publication</NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
