import React from "react";

// ROUTER
import Router from "../router/Router";

function App() {
  return <Router />;
}

export { App as default };
