import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

// LAYOUTS
import RootLayout from "../layouts/RootLayout";

// pages
import HomePage from "../pages/Homepage/HomePage";
import AboutPage from "../pages/AboutPage/AboutPage";
import ServicesPage from "../pages/ServicesPage/ServicesPage";
import Contact from "../pages/Contact/Contact";
import { BlogDetail, Blogs } from "../pages/Blogs";
import { Publication, PublicationDetail } from "../pages/Publication";
import { Staff } from "../pages/Staff";
import ServiceDetail from "../pages/ServicesPage/ServiceDetail";
import ServiceForm from "../pages/ServicesPage/ServiceForm";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/servicesdetail/:service_id" element={<ServiceDetail />} />
      <Route path="/serviceform" element={<ServiceForm />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blogdetail/:id" element={<BlogDetail />} />
      <Route path="/publication" element={<Publication />} />
      <Route
        path="/publicationdetail/:pub_id"
        element={<PublicationDetail />}
      />
      <Route path="/teammembers" element={<Staff />} />
    </Route>
  )
);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
