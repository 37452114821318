import { configureStore } from "@reduxjs/toolkit";
import homeDataReducer from "../features/homepage/hompageSlice";
import serviceReducer from "../features/services/servicesSlice";
import blogReducer from "../features/blog/blogSlice";
import publicationReducer from "../features/publication/publicationSlice";

export const store = configureStore({
  reducer: {
    homeData: homeDataReducer,
    services: serviceReducer,
    blogs: blogReducer,
    publications: publicationReducer,
  },
});
