import React, { useEffect } from "react";
import { SectionWrapper } from "../../hoc";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPublications } from "../../features/publication/publicationSlice";
import { MutableButton } from "../../components/Button";
import Spinner from "../../components/Spinner";

const PublicationDetail = () => {
  const dispatch = useDispatch();
  const { publications, isLoading } = useSelector(
    (state) => state.publications
  );
  const { pub_id } = useParams();

  const publication = () => {
    if (publications) {
      const pub = publications?.data?.find((pub) => pub?.pub_id === pub_id);

      return pub;
    }
    return null;
  };

  const pub = publication();

  useEffect(() => {
    dispatch(getPublications());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="publication">
      <p className="publication__title">{pub?.title}</p>

      <p className="publication__para">{pub?.abstract}</p>

      <MutableButton
        text={"Research Link"}
        onClick={() => window.open(pub?.url, "_blank")}
        className={"bg-transparent text-secondary"}
      />

      <div className="publication__author-container">
        <p className="publication__author">Authors: {pub?.author}</p>
      </div>
    </div>
  );
};

export default SectionWrapper(PublicationDetail);
