import React from "react";
import { motion } from "framer-motion";

// CUSTOM HOOKS
import { fadeIn, textVariant } from "../../utils/motion";

// STYLES
import { styles } from "../../styles";

// CUSTOM COMPONENTS
import { SectionWrapper } from "../../hoc";
import MutableButton from "../Button/MutableButton";
import { useNavigate } from "react-router-dom";

const FeedbackCard = ({ index, desc, name, role, company, photo, short }) => (
  <motion.div
    variants={fadeIn("", "spring", index * 0.5, 0.75)}
    className="bg-charcoal p-10 rounded-3xl xs:w-[320px] w-full"
  >
    <p className="text-white font-black text-[48px]">"</p>

    <div className="mt-1">
      <p className="text-white tracking-wider text-[18px]">
        {short ? desc.slice(0, 120) + "..." : desc}
      </p>

      <div className="mt-7 flex justify-between items-center gap-1">
        <div className="flex-1 flex flex-col">
          <p className="text-white font-medium text-[16px]">
            <span className="blue-text-gradient">@</span> {name}
          </p>
          <p className="mt-1 text-secondary text-[12px]">
            {role} of {"Jaris"}
          </p>
        </div>

        <img
          src={require(`./staff/${photo}`)}
          alt={`feedback_by-${name}`}
          className="w-10 h-10 rounded-full object-cover"
        />
      </div>
    </div>
  </motion.div>
);

const TeamMembers = ({ short, testimonials }) => {
  const navigate = useNavigate();
  return (
    <div className={`mt-12 bg-brand-navyblue rounded-[20px]`}>
      <div
        className={`bg-tertiary rounded-2xl ${styles.padding} min-h-[300px]`}
      >
        <motion.div variants={textVariant()}>
          <p className={styles.sectionSubText}>Jaris Team Members</p>
          <h2 className={styles.sectionHeadText}>
            Meet some of our team members
          </h2>
        </motion.div>
      </div>
      <div className={`-mt-20 pb-14 ${styles.paddingX} flex flex-wrap gap-7`}>
        {testimonials?.map((testimonial, index) => (
          <FeedbackCard
            key={testimonial.name}
            index={index}
            {...testimonial}
            short={short}
          />
        ))}
        <div className="w-full flex justify-end">
          <MutableButton
            onClick={() => navigate("/teammembers")}
            text={"View all"}
            className={"bg-transparent text-sm text-secondary"}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionWrapper(TeamMembers);
