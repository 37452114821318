import React from "react";

// ICONS
import { FaPhone, FaRegClock } from "react-icons/fa";
import { ImLocation } from "react-icons/im";

const NavContact = () => {
  return (
    <div className="nav-contact">
      <div className="nav-contact__container">
        <div className="nav-contact__item" title="Call">
          <FaPhone />
          <p>+2348165075168</p>
        </div>
        <div className="nav-contact__item" title="Work Time">
          <FaRegClock />
          <p>Mon - Fri 8 AM - 5 PM</p>
        </div>
        <div className="nav-contact__item" title="Location">
          <ImLocation />
          <p>Jos, Plateau State, Nigeria</p>
        </div>
      </div>
    </div>
  );
};

export default NavContact;
