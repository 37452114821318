import React from "react";

const StackedCard = ({ src, alt, name, amount, onClick }) => {
  return (
    <div className="stacked-card">
      <img className="w-full" src={src} alt={alt} />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{name}</div>
      </div>
      <div className="stacked-card__actions">
        <button
          onClick={onClick}
          className="px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
        >
          Read more...
        </button>

        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          {amount}
        </span>
      </div>
    </div>
  );
};

export default StackedCard;
