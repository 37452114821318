import React from "react";
import { motion } from "framer-motion";

// ICONS
import { FaPhone, FaRegClock, FaFacebookF, FaTelegram } from "react-icons/fa";
import { IoLogoWhatsapp, IoLogoLinkedin } from "react-icons/io";
import { RiInstagramFill } from "react-icons/ri";
import { ImLocation } from "react-icons/im";
import { SectionWrapper } from "../../hoc";
import { fadeIn } from "../../utils/motion";
import { Link } from "react-router-dom";
import { MutableButton } from "../Button";

const Footer = () => {
  return (
    <div className="footer">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 border-b w-full pb-3">
        <motion.div
          variants={fadeIn("right", "spring", 1 * 0.5, 0.75)}
          className="footer__contact-media"
        >
          <p className="contact-media-title">Contact Media</p>
          <div className="contact-media-item">
            <FaPhone className="text-brand-cyan" fontSize={18} />
            <div className="contact-media-contact">
              <p className="font-medium text-sm">WhatsApp</p>
              <p className="text-sm text-neutral-500 leading-6">
                +234 8165 075 168
              </p>
            </div>
          </div>
          <div className="contact-media-item">
            <FaRegClock className="text-brand-cyan" fontSize={18} />
            <div className="contact-media-contact">
              <p className="font-medium text-sm">Work Time</p>
              <p className="text-sm text-neutral-500 leading-6">
                Mon - Fri 8 AM - 5 PM
              </p>
            </div>
          </div>
          <div className="contact-media-item">
            <ImLocation className="text-brand-cyan" fontSize={18} />
            <div className="contact-media-contact">
              <p className="font-medium text-sm">Location</p>
              <p className="text-sm text-neutral-500 leading-6">
                Jos, Plateau State, Nigeria
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          variants={fadeIn("right", "spring", 2 * 0.5, 0.75)}
          className="footer__quick-links"
        >
          <p className="quick-links-title">Quick Links</p>

          <ul className="quick-links-ul">
            <li className="quick-links-ul__li">
              <Link to="/about">About Us</Link>
            </li>
            <li className="quick-links-ul__li">
              <Link to="/services">Our Services</Link>
            </li>
            {/* <li className="quick-links-ul__li">
              <Link to="">Jaris Researhers</Link>
            </li> */}
            <li className="quick-links-ul__li">
              <Link to="/contact">Contact</Link>
            </li>
            <li className="quick-links-ul__li">
              <Link to="/publication">Publication</Link>
            </li>
            <li className="quick-links-ul__li">
              <Link to="/blogs">Blog</Link>
            </li>
          </ul>
        </motion.div>

        <motion.div
          variants={fadeIn("right", "spring", 3 * 0.5, 0.75)}
          className="footer__other-links"
        >
          <p className="other-link-title">Other/Other Payment Links</p>

          <div className="other-link-buttons">
            <MutableButton text={"Payment"} />
            <MutableButton text={"Login"} />
            <MutableButton text={"Logout"} />
          </div>
        </motion.div>
      </div>

      <div className="mt-8 flex flex-col md:flex-row justify-between w-full">
        <motion.p
          variants={fadeIn("right", "spring", 0.5, 0.75)}
          className="text-neutral-500 mb-5"
        >
          &copy; Copyrights 2022 Smart Developer All rights reserved
        </motion.p>

        <motion.div
          variants={fadeIn("left", "spring", 0.5, 0.75)}
          className="footer__social-media"
        >
          <div className="social-media-icon">
            <Link>
              <FaFacebookF />
            </Link>
          </div>
          <div className="social-media-icon ml-2">
            <Link>
              <FaTelegram />
            </Link>
          </div>
          <div className="social-media-icon ml-2">
            <Link>
              <IoLogoWhatsapp />
            </Link>
          </div>
          <div className="social-media-icon ml-2">
            <Link>
              <RiInstagramFill />
            </Link>
          </div>
          <div className="social-media-icon ml-2">
            <Link>
              <IoLogoLinkedin />
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default SectionWrapper(Footer);
