import React from "react";

const MutableButton = ({ className, endIcon, onClick, startIcon, text }) => {
  return (
    <div
      onClick={onClick}
      className={`flex items-center justify-center py-2 px-1 rounded-md hover:scale-105 hover:opacity-80 font-medium text-brandWhite mb-3 transition-all duration-500 bg-brand-cyan w-fit cursor-pointer ${className}`}
    >
      <div className="mr-2">{startIcon}</div>
      {text}
      <div className="ml-2">{endIcon}</div>
    </div>
  );
};

export default MutableButton;
