import axios from "axios";

const API_URL = "https://jaris-api.vercel.app/api/blog";

const getBlogs = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const blogService = {
  getBlogs,
};

export default blogService;
