import React from "react";
import { WelcomeSection } from "../../components/IntroScetion";
import { ServiceTimeline } from "../../components/OurServices";
import { TeamMembers } from "../../components/TeamMembers";
import { WorkShop } from "../../components/WorkShop";
import ConductResearch from "./ConductResearch";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getHomeData } from "../../features/homepage/hompageSlice";
import { EventSection } from "../../components/EventSection";

// CUSTOM COMPONETNS
import Spinner from "../../components/Spinner";
// import { FindResearch, ResearchCard } from "../../components/FindResearch";

const HomePage = () => {
  const dispatch = useDispatch();
  const { homeData, isLoading } = useSelector((state) => state.homeData);

  // THIS IS ASSIGNED TO THE TEAM MEMBERS
  const teamMembers = homeData?.data?.staffs.slice(0, 3);

  // THIS IS ASSIGNED TO THE SERVICES
  const services = homeData?.data?.services.slice(0, 4);

  useEffect(() => {
    dispatch(getHomeData());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <WelcomeSection />
      <EventSection />
      <ConductResearch />
      <ServiceTimeline services={services} short />
      <TeamMembers testimonials={teamMembers} short />
      {/* <FindResearch
        children={homeData?.data?.researches.slice(0, 3).map((data, index) => (
          <ResearchCard
            key={index}
            title={`Group Title: ${data.title}`}
            topic={`Group Topic: ${data.topic}`}
          />
        ))}
      /> */}
      <WorkShop />
    </div>
  );
};

export { HomePage as default };
