import React, { useEffect } from "react";
import { WelcomeSection } from "../../components/IntroScetion";
import AboutUse from "./AboutUse";
import { ServiceTimeline } from "../../components/OurServices";
import { useDispatch, useSelector } from "react-redux";
import { getHomeData } from "../../features/homepage/hompageSlice";
import Spinner from "../../components/Spinner";

const AboutPage = () => {
  const dispatch = useDispatch();
  const { homeData, isLoading } = useSelector((state) => state.homeData);

  // THIS IS ASSIGNED TO THE SERVICES
  const services = homeData?.data?.services.slice(0, 4);

  useEffect(() => {
    dispatch(getHomeData());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <WelcomeSection />
      <AboutUse />
      <ServiceTimeline services={services} short />
    </div>
  );
};

export default AboutPage;
