import React from "react";
import { SectionWrapper } from "../../hoc";

const ServiceForm = () => {
  return (
    <div className="flex flex-col bg-brandWhite rounded-md shadow-md p-5">
      <p className="text-xl text-brand-navyblue font-semibold mb-3">
        KINDLY FILL THE FORM BELOW
      </p>
      <form className="w-full">
        <div className="flex flex-col md:flex-row gap-4 mb-4">
          <div className="flex flex-col flex-1">
            <label htmlFor="name">Name of researcher</label>
            <input
              type="text"
              className="p-3 w-full rounded-xl border border-charcoal outline-none bg-transparent"
              name="name"
              placeholder="E.g Simon Itodo"
            />
          </div>

          <div className="flex flex-col flex-1">
            <label htmlFor="email">E-Mail</label>
            <input
              type="email"
              className="p-3 w-full rounded-xl border border-charcoal outline-none bg-transparent"
              name="email"
              placeholder="E.g itodo@gmail.com"
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-4 mb-4">
          <div className="flex flex-col flex-1">
            <label htmlFor="number">Phone Number</label>
            <input
              type="number"
              className="p-3 w-full rounded-xl border border-charcoal outline-none bg-transparent"
              name="number"
              placeholder="E.g +2348167235468"
            />
          </div>

          <div className="flex flex-col flex-1">
            <label htmlFor="institute">Institution(Affiliation)</label>
            <input
              type="text"
              className="p-3 w-full rounded-xl border border-charcoal outline-none bg-transparent"
              name="institute"
              placeholder="E.g University of Jos"
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-4 mb-4">
          <div className="flex flex-col flex-1">
            <label htmlFor="number">Type of analysis</label>
            <select className="p-3 w-full rounded-xl border border-charcoal outline-none bg-transparent">
              <option value="">Select One</option>
            </select>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-4 mb-4">
          <div className="flex flex-col flex-1">
            <label htmlFor="number">Brief description of the work</label>
            <textarea
              cols={30}
              className="p-3 w-full rounded-xl border border-charcoal outline-none bg-transparent"
            />
          </div>
        </div>

        <div className="">
          <button
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default SectionWrapper(ServiceForm);
