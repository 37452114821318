import React from "react";
import { motion } from "framer-motion";

// CUSTOM COMPONENTS
import { SectionWrapper } from "../../hoc";

// HOOKS
import { fadeIn, textVariant } from "../../utils/motion";
import Tilt from "react-tilt";

// DUMMY DATA
import { researchData } from "../../constants/data";

const ConductResearch = () => {
  return (
    <div className="">
      <motion.div variants={textVariant()} className="conduct-research">
        <p className="conduct-research__title">
          The Smarter Way to Conduct Research
        </p>
        <p className="conduct-research__sub-title">
          We use computational approaches and methods to solve real-life
          biologically related problems.
        </p>
      </motion.div>

      <div className="mt-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10">
        {researchData.map((data, index) => (
          <Tilt className="w-full" key={data.id}>
            <motion.div
              variants={fadeIn("right", "spring", index * 0.5, 0.75)}
              className="w-full green-pink-gradient p-[1px] rounded-[20px]"
            >
              <div
                options={{
                  max: 45,
                  scale: 10,
                  speed: 450,
                }}
                className="bg-tertiary rounded-[20px] py-5 px-4 min-h-[280px] flex justify-evenly items-center flex-col"
              >
                <img
                  src={data.icon}
                  alt="web-development"
                  className="w-16 h-16 object-contain"
                />

                <h3 className="text-white text-[20px] font-bold text-center">
                  {data.title}
                </h3>

                <p className="text-brandWhite text-sm">{data.body}</p>
              </div>
            </motion.div>
          </Tilt>
        ))}
      </div>
    </div>
  );
};

export default SectionWrapper(ConductResearch);
