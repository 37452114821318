import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import homeDataService from "./homepageService";

const initialState = {
  homeData: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// GET STORES
export const getHomeData = createAsyncThunk(
  "homeDatas/getAll",
  async (_, thunkAPI) => {
    try {
      return await homeDataService.getHomeData();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const homeDataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomeData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHomeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.homeData = action.payload;
      })
      .addCase(getHomeData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = homeDataSlice.actions;
export default homeDataSlice.reducer;
