import axios from "axios";

const API_URL = "https://jaris-api.vercel.app/api/pub";

const getPublication = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const publicationService = {
  getPublication,
};

export default publicationService;
