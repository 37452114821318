import {
  icon1,
  icon2,
  icon3,
  icon4,
  june,
  post1,
  molecular_docking,
  titi,
  yanju2,
  yomi,
} from "../assets";

export const researchData = [
  {
    id: 1,
    title: "Awesome Researchers",
    body: "Our Researchers, have been recognozied as one of the industries best due to their level of expirence.",
    icon: icon1,
  },
  {
    id: 2,
    title: "Global Certificate",
    body: "Our training and certification program offers a path to the alot of tech research careers you want.",
    icon: icon2,
  },
  {
    id: 3,
    title: "Best Programme",
    body: "Access valuable resources, attend webinars, and participate in discussions.Access valuable resources, attend webinars, and participate in discussions.",
    icon: icon3,
  },
  {
    id: 4,
    title: "Research Support Service",
    body: "Our Communities of researchers provide a gathering place where you can ask your questions, share ideas, and participate in serveral research topics and areas.",
    icon: icon4,
  },
];

export const services = [
  {
    id: 1,
    title: "QSAR MODELLING",
    body: "The quantitative structure-activity relationship (QSAR) is a computational modeling technique for determining relationships between chemical compound structural features and biological activities. QSAR modeling is critical for drug development.",
    company_name: "Jaris Service",
    icon: icon3,
    iconBg: "#383E56",
  },
  {
    id: 1,
    title: "SEQUENCE ALIGNMENT",
    body: "A sequence alignment is a method of arranging DNA, RNA, or protein sequences in order to find regions of similarity that may be the result of functional, structural, or evolutionary links between the sequences. ",
    company_name: "Jaris Service",
    icon: icon3,
    iconBg: "#383E56",
  },
  {
    id: 1,
    title: "PHYLOGENY BUILDING",
    body: "A phylogenetic tree depicts the relationships between taxa (or sequences) and their presumed common ancestors. There are four steps to creating a phylogenetic tree: (Step 1) find and obtain a set of homologous DNA or protein sequences, (Step 2) align those sequences, (Step 3) estimate a tree from the aligned sequences, and (Step 4) show that tree in such a way that the necessary information is clearly conveyed to others.",
    company_name: "Jaris Service",
    icon: icon3,
    iconBg: "#383E56",
  },
  {
    id: 1,
    title: "PHARMACOPHORE MODELLING",
    body: "During virtual screening on large scale chemical databases, pharmacophore is a set of steric and electronic properties that ensure optimum supramolecular interactions. It is a more potent and efficient strategy for finding compounds against specific targets to induce or inhibit macromolecular activity than molecular docking. Pharmacophores are schematic representations of the main aspects of molecular recognition that can be used to represent and identify compounds on a 2D or 3D level. Virtual screening is frequently used to identify compounds that cause the intended biological effect using pharmacophore modeling. ",
    company_name: "Jaris Service",
    icon: icon3,
    iconBg: "#383E56",
  },
];

export const testimonials = [
  {
    testimonial:
      "I thought it was impossible to make a website as beautiful as our product, but Rick proved me wrong.",
    name: "Yomi",
    designation: "CFO",
    company: "Acme Co",
    image: yomi,
  },
  {
    testimonial:
      "I've never met a web developer who truly cares about their clients' success like Rick does.",
    name: "Chris Brown",
    designation: "COO",
    company: "DEF Corp",
    image: yanju2,
  },
  {
    testimonial:
      "After Rick optimized our website, our traffic increased by 50%. We can't thank them enough!",
    name: "Titi",
    designation: "CTO",
    company: "456 Enterprises",
    image: titi,
  },
];

export const projects = [
  {
    name: "Molecular docking",
    description:
      "We’re glad to invite you for a 2 day pre-conference workshop organized by Baze University in collaboration with Jaris computational biology centre on MOLECULAR DOCKING",
    tags: [
      {
        name: "11/09/2023",
        color: "blue-text-gradient",
      },
      {
        name: "Admin",
        color: "green-text-gradient",
      },
      {
        name: "Jaris,Research",
        color: "pink-text-gradient",
      },
    ],
    link: "https://forms.gle/cGC74A9RtdJKk2DX6",

    image: molecular_docking,
    source_code_link: "https://github.com/",
  },
  {
    name: "Computer-Aided Drug Design",
    description:
      "Web application that enables users to search for job openings, view estimated salary ranges for positions, and locate available jobs based on their current location.",
    tags: [
      {
        name: "25/05/2020",
        color: "blue-text-gradient",
      },
      {
        name: "Admin",
        color: "green-text-gradient",
      },
      {
        name: "Jaris,Research",
        color: "pink-text-gradient",
      },
    ],
    image: post1,
    source_code_link: "https://github.com/",
  },
  {
    name: "Bioinformatics workshop",
    description:
      "A comprehensive travel booking platform that allows users to book flights, hotels, and rental cars, and offers curated recommendations for popular destinations.",
    tags: [
      {
        name: "25/05/2022",
        color: "blue-text-gradient",
      },
      {
        name: "Admin",
        color: "green-text-gradient",
      },
      {
        name: "Jaris,Research",
        color: "pink-text-gradient",
      },
    ],
    image: june,
    source_code_link: "https://github.com/",
  },
];

export const findResearch = [
  {
    id: 1,
    topic: "Cancer Research",
    title: "Colorectal Cancer",
  },
  {
    id: 2,
    topic: "Cancer Research",
    title: "Cervical Cancer",
  },
  {
    id: 3,
    topic: "Cancer Research",
    title: "Brain Cancer",
  },
];
