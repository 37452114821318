import React from "react";
import { Outlet } from "react-router-dom";

// CUSTOM COMPONENTS
import { Footer } from "../components/Footer";
import { NavBar } from "../components/NavBar";

const RootLayout = () => {
  return (
    <div>
      {/* CONTAINS THE NAVBAR */}
      <NavBar />
      <div className="min-h-screen">
        {/* RENDERS CHILDREN IN THE LAYOUT */}
        <Outlet />
      </div>
      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default RootLayout;
