import React from "react";
import { FaHighlighter } from "react-icons/fa";
import { jarisLogo2 } from "../../assets";
import { SectionWrapper } from "../../hoc";

const AboutUse = ({ children }) => {
  return (
    <div className="about-us">
      <div>
        <p className="about-us__title">ABOUT US</p>
        <p className="about-us__sub-title">Our Mission</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
        <div>
          <img src={jarisLogo2} alt="JARIS LOGO" className="mt-5" />
        </div>

        <div>
          <div className="flex flex-col">
            <div className="about-us__para-container">
              <div>
                <FaHighlighter className="text-brand-cyan" fontSize={16} />
              </div>
              <p>
                To simplify and expedite the process of drug discovery and
                development
              </p>
            </div>

            <div className="about-us__para-container">
              <div>
                <FaHighlighter className="text-brand-cyan" fontSize={16} />
              </div>
              <p>To enhance research collaboration across the world</p>
            </div>

            <div className="about-us__para-container">
              <div>
                <FaHighlighter className="text-brand-cyan" fontSize={16} />
              </div>
              <p>
                To bridge the knowledge gap in the aspect of computer-aided
                scientific research via workshops, seminars, and conferences
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionWrapper(AboutUse);
