import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import servicesService from "./servicesServices";

const initialState = {
  services: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// GET STORES
export const getServices = createAsyncThunk(
  "services/getAll",
  async (_, thunkAPI) => {
    try {
      return await servicesService.getServices();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.services = action.payload;
      })
      .addCase(getServices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = serviceSlice.actions;
export default serviceSlice.reducer;
