import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const StaffModal = ({ isOpen, toggle, teamMember }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader
        toggle={toggle}
        className="capitalize font-semibold text-lg border"
      >
        {teamMember?.name}
      </ModalHeader>
      <ModalBody>
        {teamMember && (
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="flex justify-center ">
              <img
                src={require(`./staff/${teamMember?.photo}`)}
                alt={teamMember?.name}
                className="img-fluid rounded h-60 w-60"
              />
            </div>
            <div>
              <p className="text-muted mb-2">{teamMember?.role}</p>
              <p>{teamMember?.desc}</p>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="tetiary" className="border" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default StaffModal;
