import React, { useState } from "react";
import { styles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getHomeData } from "../../features/homepage/hompageSlice";
import Spinner from "../../components/Spinner";
import { SectionWrapper } from "../../hoc";
import StaffModal from "./StaffModal";

const Staff = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const { homeData, isLoading } = useSelector((state) => state.homeData);

  // THIS IS ASSIGNED TO THE TEAM MEMBERS
  const teamMembers = homeData?.data?.staffs;

  console.log(teamMembers);

  // Reverse the order of teamMembers
  const reversedTeamMembers = teamMembers ? [...teamMembers].reverse() : [];

  const handleReadMoreClick = (teamMember) => {
    setSelectedTeamMember(teamMember);
    setModalOpen(true);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setSelectedTeamMember(null);
  };

  useEffect(() => {
    dispatch(getHomeData());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="">
      <div className="mb-10">
        <p className={styles.sectionSubText}>Jaris Team Members</p>
        <h2 className="text-tertiary font-black md:text-[50px] sm:text-[30px] xs:text-[20px] text-[20px]">
          Meet some of our team members
        </h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
        {reversedTeamMembers.map((team) => (
          <div
            className="shadow-lg h-[500px] rounded-md cursor-pointer"
            key={team.name}
          >
            <img
              onClick={() => window.open(team?.r_url, "_blank")}
              className="w-full h-3/4 rounded-t-md"
              src={require(`./staff/${team?.photo}`)}
              alt={team.name}
            />

            <div className="w-full p-2">
              <p className="text-xl capitalize font-semibold text-tertiary">
                {team?.name}
              </p>
              <p className="text-xs font-medium text-slate-400">{team?.role}</p>

              <p className="text-charcoal tracking-wider text-[14px]">
                {team?.desc.slice(0, 50) + "..."}{" "}
                <span
                  className="text-gray-400 hover:text-base transition-all duration-500"
                  onClick={() => handleReadMoreClick(team)}
                >
                  Read more
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Display the modal when a team member is selected */}
      <StaffModal
        isOpen={modalOpen}
        toggle={toggleModal}
        teamMember={selectedTeamMember}
      />
    </div>
  );
};

export default SectionWrapper(Staff);
