import React from "react";
import SectionWrapper from "../../hoc/SectionWrapper";
import MutableButton from "../../components/Button/MutableButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getBlogs } from "../../features/blog/blogSlice";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";

const Blogs = () => {
  const dispatch = useDispatch();
  const { blogs, isLoading } = useSelector((state) => state.blogs);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getBlogs());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
      {blogs?.data?.map((blog) => (
        <div
          key={blog?.post_id}
          className="card p-5 shadow-lg bg-brandWhite rounded-lg"
        >
          <img
            className="rounded-lg h-56 w-full"
            src={require(`./blog/${blog?.photo}`)}
            alt=""
          />

          <div className="flex flex-col mt-3 text-secondary">
            <div className="flex justify-between w-full">
              <p className="text-xs">{moment(blog?.date).calendar()}</p>
              <p className="text-xs">by {blog?.author}</p>
              <p className="text-xs">#Jaris,Research</p>
            </div>
          </div>

          <div className="mt-3">
            <p className="font-semibold text-brand-navyblue text-lg mb-2">
              {blog?.title}
            </p>
            <p className="text-sm">{blog?.desc.slice(0, 100) + "..."}</p>
            <MutableButton
              onClick={() => navigate(`/blogdetail/${blog?.post_id}`)}
              className={"bg-transparent text-secondary"}
              text={"Read More..."}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SectionWrapper(Blogs);
