import React from "react";
import { SectionWrapper } from "../../hoc";
import { GiRotaryPhone } from "react-icons/gi";
import { MdAlternateEmail } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getServices } from "../../features/services/servicesSlice";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";

const ServiceDetail = () => {
  const dispatch = useDispatch();
  const { service_id } = useParams();
  const { services, isLoading } = useSelector((state) => state.services);

  const _services = () => {
    if (services) {
      const service = services?.data?.find(
        (item) => item?.service_id === service_id
      );
      return service;
    }
    return null;
  };

  const service = _services();

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  if (!service) {
    return <div>No service found.</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
      <div className="">
        <img
          className="w-full h-auto rounded-md"
          src={require(`./service/${service.photo}`)}
          alt={service.name}
        />
      </div>
      <div className="flex flex-col">
        <p className="text-xl text-brand-navyblue font-semibold mb-3">
          Anything you want to know
        </p>
        <div className="flex flex-col md:flex-row">
          <div className="flex items-center mb-5">
            <GiRotaryPhone fontSize={40} />
            <div className="flex flex-col ml-3">
              <p className="font-semibold">Call</p>
              <p className="text-sm">+234 8060 775 453</p>
            </div>
          </div>

          <div className="flex items-center mb-5 ml-2">
            <MdAlternateEmail fontSize={40} />
            <div className="flex flex-col ml-3">
              <p className="font-semibold">Email</p>
              <p className="text-sm">jarisresearch@gmail.com</p>
            </div>
          </div>
        </div>

        <p className="text-xl text-brand-navyblue font-semibold mb-2">
          {service?.name}
        </p>

        <p className="">{service?.desc}</p>
      </div>
    </div>
  );
};

export default SectionWrapper(ServiceDetail);
